<template>
  <div class="content">
    <div class="banner-list">
      <div class="w">
        <div class="banner-list-wenzi">
          <div class="banner-left">
            <!-- <div slot="reference" class="banner-title">
							<p class="qk">期刊分类</p>
						</div> -->
            <div class="flexcolumn">
              <div class="flex1">
                <el-popover
                  placement="right"
                  title="学术分类"
                  width="400"
                  trigger="hover"
                >
                  <div class="left-list">
                    <ul v-for="(item, index) in AcademictypesList" :key="index">
                      <li @click="learning(item.id)">{{ item.types }}</li>
                    </ul>
                  </div>
                  <div slot="reference" class="banner-title-list">
                    <div style="width: 100%; height: 65px" class="ces">
                      <div class="bes">
                        <span style="width: 85px; height: 20px">学术分类</span>
                        <img
                          src="../../../Newimg/right.png"
                          alt=""
                          class="go_more"
                        />
                      </div>
                      <div class="des">
                        <div class="left-li">
                          <ul
                            v-for="(item, index) in AcademictypesList"
                            :key="index"
                          >
                            <li @click="learning(item.id)">{{ item.types }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div class="flex1">
                <el-popover
                  placement="right"
                  title="期刊收录"
                  width="400"
                  trigger="hover"
                >
                  <div
                    class="left-list"
                    v-for="(item, index) in Include"
                    :key="index"
                  >
                    <ul>
                      <li @click="JournalCollection(item.id)">
                        {{ item.include }}
                      </li>
                    </ul>
                  </div>
                  <div slot="reference" class="banner-title-list">
                    <div style="width: 100%; height: 65px" class="ces">
                      <div class="bes">
                        <span style="width: 85px; height: 20px">期刊收录</span>
                        <img
                          src="../../../Newimg/right.png"
                          alt=""
                          class="go_more"
                        />
                      </div>
                      <div class="des">
                        <div
                          class="left-li"
                          v-for="(item, index) in Include"
                          :key="index"
                        >
                          <ul>
                            <li @click="JournalCollection(item.id)">
                              {{ item.include }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div class="flex1">
                <el-popover
                  placement="right"
                  title="发行周期"
                  width="400"
                  trigger="hover"
                >
                  <div
                    class="left-list"
                    v-for="(item, index) in publishcycle"
                    :key="index"
                  >
                    <ul>
                      <li @click="Issuecycle(item.name, index)">
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                  <div slot="reference" class="banner-title-list">
                    <div style="width: 100%; height: 65px" class="ces">
                      <div class="bes">
                        <span style="width: 85px; height: 20px">发行周期</span>
                        <img
                          src="../../../Newimg/right.png"
                          alt=""
                          class="go_more"
                        />
                      </div>
                      <div class="des">
                        <div
                          class="left-li"
                          v-for="(item, index) in publishcycle"
                          :key="index"
                        >
                          <ul>
                            <li @click="Issuecycle(item.name, index)">
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div class="flex1">
                <el-popover
                  placement="right"
                  title="出版地区"
                  width="400"
                  trigger="hover"
                >
                  <div
                    class="left-list"
                    v-for="(item, index) in publishing"
                    :key="index"
                  >
                    <ul>
                      <li @click="DepartureArea(item.area, item.id)">
                        {{ item.area }}
                      </li>
                    </ul>
                  </div>
                  <div slot="reference" class="banner-title-list">
                    <div style="width: 100%; height: 65px" class="ces">
                      <div class="bes">
                        <span style="width: 85px; height: 20px">出版地区</span>
                        <img
                          src="../../../Newimg/right.png"
                          alt=""
                          class="go_more"
                        />
                      </div>
                      <div class="des">
                        <div
                          class="left-li"
                          v-for="(item, index) in publishing"
                          :key="index"
                        >
                          <ul>
                            <li @click="DepartureArea(item.area, item.id)">
                              {{ item.area }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>

              <div class="flex1">
                <el-popover
                  placement="right"
                  title="审校时间"
                  width="400"
                  trigger="hover"
                >
                  <div
                    class="left-list"
                    v-for="(item, index) in checktime"
                    :key="index"
                  >
                    <ul>
                      <li @click="ReviewTime(item.time, index)">
                        {{ item.time }}
                      </li>
                    </ul>
                  </div>
                  <div slot="reference" class="banner-title-list">
                    <div style="width: 100%; height: 65px" class="ces">
                      <div class="bes">
                        <span style="width: 85px; height: 20px">审校时间</span>
                        <img
                          src="../../../Newimg/right.png"
                          alt=""
                          class="go_more"
                        />
                      </div>
                      <div class="des">
                        <div
                          class="left-li"
                          v-for="(item, index) in checktime"
                          :key="index"
                        >
                          <ul>
                            <li @click="ReviewTime(item.time, index)">
                              {{ item.time }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
       <!--    <div class="flex1">
                <el-popover
                  placement="right"
                  title="影响因子"
                  width="400"
                  trigger="hover"
                >
                  <div
                    class="left-list"
                    v-for="(item, index) in listdatalist"
                    :key="index"
                  >
                    <ul>
                      <li @click="ReviewTimes(item.num, index)">
                        {{ item.num }}
                      </li>
                    </ul>
                  </div>
                  <div
                    slot="reference"
                    class="banner-title-list"
                    style="border-bottom: 0"
                  >
                    <div style="width: 100%; height: 65px" class="ces">
                      <div class="bes">
                        <span style="width: 85px; height: 20px">影响因子</span>
                        <img
                          src="../../../Newimg/right.png"
                          alt=""
                          class="go_more"
                        />
                      </div>
                      <div class="des">
                        <div
                          class="left-li"
                          v-for="(item, index) in listdatalist"
                          :key="index"
                        >
                          <ul>
                            <li @click="ReviewTimes(item.num, index)">
                              {{ item.num }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
-->
            </div>
          </div>

          <!-- 轮播图 -->
          <div style="flex: 3">
            <div style="display: flex">
              <div class="banner-lunbo">
                <div class="block">
                  <el-carousel trigger="click" height="358px">
                    <el-carousel-item
                      v-for="(item, index) in bannerrList"
                      :key="index"
                      class="carousel-item"
                    >
                      <img
                        :src="url + item.cover"
                        @click="golink(item.link)"
                        alt=""
                        class="carousel-img"
                      />
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <div class="right">
                <div class="right-list">
                  <div class="right-list-wen">
                    <span class="wenzi-title"> 人气文献</span>
                    <span class="gengduo" @click="GengDuo()"> 更多>></span>
                  </div>
                  <div class="wenzi-footer">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.name"
                      placement="top-start"
                      v-for="(item, index) in renQiList"
                      :key="index"
                    >
                      <div class="wenzi-article" @click="literature(item.id)">
                        <span
                          :class="
                            index == 0
                              ? 'color1'
                              : index == 1
                              ? 'color2'
                              : index == 2
                              ? 'color3'
                              : index == 3
                              ? 'color4'
                              : ''
                          "
                          >{{ index + 1 }}</span
                        >
                        <div>{{ item.name }}</div>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <ul class="right-bottom">
              <li style="background-color: #ffe5c4">
                <div class="router" @click="chaChong">
                  <b>智能查重</b>
                  <p>神算子精准智能查重</p>
                </div>
                <img src="../../../Newimg/2.png" alt="" />
              </li>
              <li style="background-color: #c0dcee">
                <div class="router" @click="zhiGai">
                  <b>职改政策</b>
                  <p>全国政策一站查询</p>
                </div>
                <img src="../../../Newimg/02.png" alt="" />
              </li>
              <li style="background-color: #f0cec8">
                <div class="router" @click="touGao">
                  <b>智能投稿</b>
                  <p>智多星大数据分析</p>
                </div>
                <img src="../../../Newimg/33.png" alt="" />
              </li>
              <li style="background-color: #badfe8">
                <div class="router" @click="Team">
                  <b>顾问服务</b>
                  <p>顶级作家在线传授</p>
                </div>
                <img src="../../../Newimg/01.png" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 期刊推荐 -->
    <div class="ssdew">
      <div class="bg">
        <div class="w mcont">
          <div class="magzines">
            <div class="mthem" @click="goqikan" style="cursor: pointer">
              {{ them }}
              <div class="mthems"></div>
            </div>
            <div class="magpic">
              <div
                v-for="(val, index) in cont"
                :key="index"
                @click="Recommended(val.qikan_id, val.qikantype_id)"
              >
                <img :src="url + val.cover" alt="" />
                <span>{{ val.qk_name }}</span>
              </div>
            </div>
            <!-- <div class="more" @click="ChaKan">查看更多</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="down">
      <img class="adjpg" :src="adjpg" alt="" />
    </div>
    <!-- 最新期刊 -->
    <div
      class="bg"
      v-for="(item, index) in magzinesss"
      :key="index"
      style="backgroundcolor: #ffffff"
    >
      <div class="w mcont">
        <div class="magzines">
          <!-- @click="haha($event)" -->
          <div class="mthem">
            {{ item.thems }}
            <div class="mthems"></div>
          </div>
          <swiper class="swiper" :options="swiperOption" ref="mySwiper">
            <swiper-slide
              class="swiperse"
              v-for="(val, index) in conts"
              :data-id="val.qikan_id"
              :data-item="val.qikantype_id"
              :key="index"
            >
              <div
                class="mmm"
                style="
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  cursor: pointer;
                "
              >
                <img
                  :src="url + val.cover"
                  @click="golink(val.link)"
                  style="z-index: 1000000"
                  class="imgs"
                  :data-id="val.qikan_id"
                  :data-item="val.qikantype_id"
                />
                <div :data-id="val.qikantype_id">{{ val.qk_name }}</div>
              </div>
            </swiper-slide>
            <!-- </div> -->
            <div
              class="swiper-button-prev"
              slot="button-prev"
              @click="prev"
            ></div>
            <div
              class="swiper-button-next"
              slot="button-next"
              @click="next"
            ></div>
          </swiper>
        </div>
      </div>
    </div>
    <div></div>
    <!-- 平台优势 -->
    <div
      class="bg"
      v-for="(item, index) in magzinessss"
      :key="index"
      style="background-color: #ffffff"
    >
      <div class="w mcont">
        <div class="magzines">
          <div class="mthem">
            {{ item.themss }}
            <div class="mthems"></div>
          </div>
          <div class="magpics">
            <div v-for="(val, idx) in item.contss" :key="idx">
              <img :src="val.src" alt="" />
              <span>{{ val.desc }}</span>
              <span class="magpicname">{{ val.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 作者好评推荐 -->
    <div class="bg" style="backgroundcolor: #ffffff" v-if="imgList.length > 0">
      <div class="w mcont">
        <div class="magzines">
          <div class="mthem">
            {{ themss }}
            <div class="mthems"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footBanner">
      <div style="height: 300px">
        <swiper class="swiper" :options="swiperOptions">
          <swiper-slide
            class="swiperse"
            v-for="(item, index) in imgList"
            :key="index"
          >
            <div class="footBannersss">
              <div class="footBanners">
                <div class="footBanners-s">
                  <div style="float: left">
                    <img :src="url + item.headimg" alt="" class="zzname" />
                  </div>
                  <div>
                    <span>{{ item.editor }}</span>
                    <p>作者</p>
                  </div>
                  <div style="margin-top: 10px">
                    <img
                      v-for="(val, index) in startlevel"
                      :key="index"
                      class="start"
                      :src="item.ptevaluation_x > index ? yellowstart : start"
                    />
                  </div>
                  <span class="wenzi">{{ item.ptevaluation }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination swiperse" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <el-dialog
      title="阅读协议"
      :visible.sync="dialogVisible"
      width="60%"
      :show-close="false"
      :close-on-click-modal="false"
      center
    >
      <div
        class="agreement"
        v-for="(item, index) in agreementList"
        :key="index"
      >
        <div class="agreement-title">{{ item.agreement_name }}</div>
        <div class="agreement-content" v-html="item.agreement_content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="readAgreement"
          :disabled="agreementTime > 0"
          ><span v-if="agreementTime > 0">{{ agreementTime + "s" }}</span
          >已阅读并同意协议</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import Swiper2, { Navigation, Pagination } from "swiper";
Swiper2.use([Navigation, Pagination]);
import "swiper/swiper-bundle.css";
import Topic from "@/components/Topics.vue";
import {
  academictype,
  Include,
  Classifications,
  Classification,
  AuthorRecommend,
  renQiWenXian,
  Carousel,
} from "../../API/homepage/policy.js";
import { agreementByType, isFirstLogin } from "../../API/homepage/agreement.js";
var vm = "";
export default {
  components: {
    Topic,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      dialogVisible: false,
      adjpg: require("../../assets/image/adjpg.jpg"),
      agreementTime: 15,
      agreementContext: "",
      swiperOption: {
        slidesPerView: 5,
        // spaceBetween: 30,
        slidesPerGroup: 5,
        on: {
          click: function () {
            console.log(this);
            if (this.clickedSlide) {
              const qikan_id = this.clickedSlide.attributes["data-id"];
              const qikantype_id = this.clickedSlide.attributes["data-item"];
              vm.Recommendeds(qikan_id.value, qikantype_id.value);
            }
          },
        },
        // loopAdditionalSlides: 0,
        loop: true,
        loopFillGroupWithBlank: true,
        //自动进行轮播3.5s
        autoplay: 350,
        //设置尺寸
        setWrapperSize: true,
        //监控轮播图下面的小白点
        pagination: ".swiper-pagination",
        //触发点击事件
        paginationClickable: true,
        //鼠标滑轮
        mousewheelControl: true,
        observeParents: true,
        //左右箭头
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        //设置轮播速度
        speed: 200,
      },
      url: "", // 图片地址
      imgList: [],
      startlevel: [{}, {}, {}, {}, {}],
      xj: 0,
      xj2: 0,
      yellowstart: require("../../assets/image/setting/xing2.png"),
      start: require("../../assets/image/setting/xing1.png"),
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 1,

        loop: false,
        loopFillGroupWithBlank: true,
        autoplay: true,
        speed: 1000,
        // 显示分页
        grabCursor: true,
        notNextTick: true, // 必须
        // 配置分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        mousewheelControl: true,
      },
      conts: [], // 最新期刊
      cont: [], // 推荐期刊
      listdatas: [
        {
          name: "文学分类",
          id: "1",
        },
        {
          name: "期刊收录",
          id: "2",
        },
        {
          name: "发行周期",
          id: "3",
        },
        {
          name: "出版地区",
          id: "4",
        },
        {
          name: "审校时间",
          id: "5",
        },
      ],
      //期刊数据
      // 影响因子
      listdatalist: [
        {
          num: "0-0.5",
        },
        {
          num: "0.5-1.0",
        },
        {
          num: "1.0-2.0",
        },
        {
          num: "2.1-3.0",
        },
        {
          num: "3.1-4.0",
        },
        {
          num: "3.1-4.0",
        },
        {
          num: "4.1-5.0",
        },
        {
          num: "5.1-6.0",
        },
        {
          num: "6.1-7.0",
        },
        {
          num: "7.1-8.0",
        },
        {
          num: "8.1-9.0",
        },
        {
          num: "9.0-9.9",
        },
      ],
      them: "推荐期刊",

      renQiList: [], // 人气文献
      magzinesss: [
        {
          thems: "最新期刊",
        },
      ],
      magzinessss: [
        {
          themss: "平台优势",
          contss: [
            {
              ide: 1,
              src: require("../../../Newimg/1.png"),
              desc: "杂志合作",
              name: "八年线下深耕经验,与1000+杂志社保持着战略合作关系",
              path: "/article/detail",
            },
            {
              ide: 2,
              src: require("../../../Newimg/tougao.png"),
              desc: "官方备案",
              name: "刊大师有九大证件保障,工信、公安、网监等国家部门权威备案",
              path: "/article/detail",
            },
            {
              ide: 3,
              src: require("../../../Newimg/pingtaidanbao-01.png"),
              desc: "平台担保",
              name: "费用平台担保,保证论文发表成功后付款,不成功无理由退款",
              path: "/article/detail",
            },
            {
              ide: 4,
              src: require("../../../Newimg/3.png"),
              desc: "智能投稿",
              name: "全网数字化大数据加智能推荐,精准推荐优质期刊投稿",
              path: "/article/detail",
            },
          ],
        },
      ],

      themss: "作者好评推荐",

      bgcolor: "#fff",
      AcademictypesList: [], // 学术分类
      Include: [], // 期刊收录
      publishcycle: [
        {
          name: "周二刊",
          id: "0",
        },
        {
          name: "周刊",
          id: "1",
        },
        {
          name: "半月刊",
          id: "2",
        },
        {
          name: "月刊",
          id: "3",
        },
        {
          name: "双月刊",
          id: "4",
        },
        {
          name: "季刊",
          id: 5,
        },
        {
          name: "半年刊",
          id: "6",
        },
        {
          name: "年刊",
          id: 7,
        },
        {
          name: "旬刊",
          id: 8,
        },
      ], //发行周期
      publishing: [
        {
          area: "天津",
          id: 1,
        },
        {
          area: "北京",
          id: 2,
        },
        {
          area: "上海",
          id: 3,
        },
        {
          area: "甘肃",
          id: 4,
        },
        {
          area: "重庆",
          id: 5,
        },
        {
          area: "青海",
          id: 6,
        },
        {
          area: "安徽",
          id: 7,
        },
        {
          area: "湖南",
          id: 8,
        },
        {
          area: "河南",
          id: 9,
        },
        {
          area: "辽宁",
          id: 10,
        },
        {
          area: "贵州",
          id: 11,
        },
        {
          area: "上海",
          id: 12,
        },
        {
          area: "广东",
          id: 13,
        },
        {
          area: "浙江",
          id: 14,
        },
        {
          area: "海南",
          id: 15,
        },
        {
          area: "江苏",
          id: 16,
        },
        {
          area: "山东",
          id: 17,
        },
        {
          area: "山西",
          id: 18,
        },
        {
          area: "湖北",
          id: 19,
        },
        {
          area: "广西",
          id: 20,
        },
        {
          area: "福建",
          id: 21,
        },
        {
          area: "河北",
          id: 22,
        },
        {
          area: "内蒙古",
          id: 23,
        },
        {
          area: "宁夏",
          id: 24,
        },
        {
          area: "江西",
          id: 25,
        },
        {
          area: "吉林",
          id: 26,
        },
        {
          area: "云南",
          id: 27,
        },
        {
          area: "新疆",
          id: 28,
        },
        {
          area: "山西",
          id: 29,
        },
        {
          area: "西藏",
          id: 30,
        },
        {
          area: "黑龙江",
          id: 31,
        },
      ], //出版地区
      checktime: [
        {
          time: "1个月以内",
          ids: "1",
        },
        {
          time: "1-3个月",
          ids: "2",
        },
        {
          time: "3-6个月",
          ids: "3",
        },
        {
          time: "6-9个月",
          ids: "4",
        },
        {
          time: "1年以上",
          ids: "5",
        },
      ], //审校时间
      bannerrList: [], // 轮播
      agreementList: [], //协议列表
      agreementCode: 0, //协议类型
      agreementId: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  created() {
    vm = this;
    if (window.location.href) {
      console.log(window.location.href);
      var login_href = window.location.href.split("?");
      var login_href1 = login_href[login_href.length - 1].split("=");
      if (login_href1[1] == "ZZ") {
        console.log("作者首次登录，获取协议");
        agreementByType(1).then((res) => {
          this.dialogVisible = true;
          this.agreementList = res.data.data;
          this.agreementTime = 15;
          this.agreementCode = 0;
          this.agreementContext = setInterval((res) => {
            if (this.agreementTime == 0) {
              return;
            } else {
              this.agreementTime--;
            }
          }, 1000);
          this.agreementId = localStorage.getItem("ZZId");
          console.log();
        });
      } else if (login_href1[1] == "fuwu") {
        agreementByType(3).then((res) => {
          this.dialogVisible = true;
          this.agreementList = res.data.data;
          this.agreementTime = 15;
          this.agreementCode = 1;
          this.agreementContext = setInterval((res) => {
            if (this.agreementTime == 0) {
              return;
            } else {
              this.agreementTime--;
            }
          }, 1000);
          this.agreementId = localStorage.getItem("FuWuId");

          console.log();
        });
      } else if (login_href1[1] == "QK") {
        agreementByType(2).then((res) => {
          this.dialogVisible = true;
          this.agreementList = res.data.data;
          this.agreementTime = 15;
          this.agreementCode = 3;
          this.agreementContext = setInterval((res) => {
            if (this.agreementTime == 0) {
              return;
            } else {
              this.agreementTime--;
            }
          }, 1000);
          this.agreementId = localStorage.getItem("QKId");
          console.log();
        });
      }
    }
  },
  mounted() {
    this.url = this.URL;
    this.Academictypes();
    this.Includes();
    this.ClassificationsList();
    this.ClassificationList();
    this.AuthorRecommendList();
    this.renQiWenXianList();
    this.CarouseList();
  },
  methods: {
    golink(url) {
      if (url == "") {
        return;
      }
      console.log(url);
      window.location.href = url;
    },
    goqikan() {
      this.$router.push("/magzine?now=-1");
    },
    readAgreement() {
      this.dialogVisible = false;
      isFirstLogin(this.agreementCode, this.agreementId).then();
      this.$router.push("/homePage");
    },
    haha(target) {
      // const {dataset:{id}} = target;
      // const {dataset:{item}} = target;
      // const {tagName} = target;
      var id = target.target.dataset.id; //id
      var typeid = target.target.dataset.item; // type id

      this.Recommendeds(id, typeid);
    },
    // 学术点击跳转
    learning(e) {
      sessionStorage.setItem("xstype",e);
      this.$router.push({
        path: "/classification?now=0"
      });
    },
    // 期刊收录跳转
    JournalCollection(e) {
      sessionStorage.setItem("qkinclude",e);
      this.$router.push({
        path: "/classification?now=0"
      });
    },
    // 发行周期
    Issuecycle(e, index) {
      sessionStorage.setItem("fxcycle",e);
      this.$router.push({
        path: "/classification?now=0"
      });
    },
    // 出发地区
    DepartureArea(e, index) {
      sessionStorage.setItem("cbarea",e);
      this.$router.push({
        path: "/classification?now=0"
      });
    },
    // 审校时间
    ReviewTime(e, index) {
      console.log(e);
      sessionStorage.setItem("xstime",e);
      this.$router.push({
        path: "/classification?now=0"
      });
    },
    // 影响因子
    ReviewTimes(e, index) {
      sessionStorage.setItem("yxnum",e);
      this.$router.push({
        path: "/classification?now=0"
      });
    },
    // 点击查看更多
    ChaKan() {
      this.$router.push({
        path: "/classification?now=0",
      });
    },
    // 人气文献更多
    GengDuo() {
      this.$router.push({
        path: "/document",
      });
    },
    // 人气文献
    literature(e) {
      this.$router.push({
        path: "/article/detail",
        query: {
          did: e,
        },
      });
    },
    // 推荐期刊跳转-详情
    Recommended(e, y) {
      this.$router.push({
        path: "/postingdetails",
        query: {
          mid: e,
          qkId: y,
          them: this.them,
        },
      });
    },
    // 最新期刊
    Recommendeds(id, typeid) {
      console.log("跳转", id, typeid);
      this.$router.push({
        path: "/postingdetails",
        query: {
          mid: id,
          type: typeid,
          title: "最新期刊",
        },
      });
    },
    pingjia2(e) {
      this.xj2 = e + 1;
    },

    prev() {
      this.$refs.mySwiper[0].$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper[0].$swiper.slideNext();
    },
    // 图片箭头跳转
    target_Zhixuan() {
      this.$router.push("/magzine");
    },
    // 跳转查重页面
    chaChong() {
      // this.$message({
      //   message: "此功能还没有开放！！",
      //   type: "warning",
      // });
      this.$router.push("/duplicate");
    },
    zhiGai() {
      this.$router.push("/cpufreqgetpolicy");
    },
    touGao() {
      this.$router.push("/intelligentand");
    },
    Team() {
      this.$router.push("/Consultantteam?now=-1");
    },
    // 请求学术类型接口
    Academictypes() {
      academictype().then((res) => {
        this.AcademictypesList = res.data.data;
      });
    },
    // 期刊收录
    Includes() {
      Include().then((res) => {
        this.Include = res.data.data;
      });
    },
    // 推荐期刊
    ClassificationsList() {
      Classifications(1, 10, 1).then((res) => {
        this.cont = res.data.data;
      });
    },
    // 最新期刊
    ClassificationList() {
      Classification(1, 20, 0).then((res) => {
        console.log(res.data, "最新期刊数据-----------");
        this.$nextTick(() => {
          // console.log(this.$refs['swiper-s0'], Object.keys(this.$refs).length);
        });
        // console.log(this.$refs, Object.keys(this.$refs).length);

        // setTimeout(() => {
        // 	let swiperS = Object.keys(this.$refs);
        // 	swiperS.forEach(v => {
        // 		if (v.includes('swiper-s')) {
        // 			console.log(this.$refs[v][0].$el);
        // 			this.$refs[v][0].$el.addEventListener("click", (ev) => {
        // 				console.log(ev, "evvvvv")
        // 			})
        // 		}
        // 	})
        // }, 1000)
        this.conts = res.data.data;
      });
    },
    // 作者好评
    AuthorRecommendList() {
      AuthorRecommend(1, 20).then((res) => {
        this.imgList = res.data.data;
      });
    },
    // 人气文献
    renQiWenXianList() {
      renQiWenXian(1, 10).then((res) => {
        this.renQiList = res.data.data;
      });
    },
    //首页轮播
    CarouseList() {
      Carousel(1).then((res) => {
        this.bannerrList = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
.content {
  background-color: white;
}
.carousel-item {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.carousel-item {
  max-width: 100%;
  max-height: 100%;
}

.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}

.start {
  width: 20px;
  cursor: pointer;
}

.magzines {
  padding-bottom: 10px;
}
.mthem {
  color: #6c9be9 !important;
}
.magzines .mthem {
  text-align: center;
  height: 42px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #6c9be9 !important;
  line-height: 42px;
  margin-bottom: 20px;
}

.mcont {
  margin-top: 30px;
}

.magpic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.magpic div {
  text-align: center;
  width: 18%;
  cursor: pointer;
  margin-bottom: 10px;
}

.magpic div span {
  display: block;
  margin: 8px 0;
}

.magpic div img {
  width: 180px;
  height: 220px;
}

.magpics {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.magpics div {
  text-align: center;
  width: 24%;
  cursor: pointer;
  margin-bottom: 10px;
}

.magpics div span {
  display: block;
  margin: 8px 0;
}

.magpics div img {
  width: 90px;
  height: 90px;
  object-fit: contain;
}

.magpicname {
  display: block;
  margin: 8px 0;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.more {
  width: 100px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #ff1918;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #ff1918;
  cursor: pointer;
}

.box {
  width: 100%;
  height: 200px;
  background: #000000;
  opacity: 0.7;
  color: #fff;
  /* background: url(../../../Newimg/数字.jpg) round; */
}

.num {
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.num li {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner-list {
  width: 100%;
  height: 480px;
  background-image: url(../../../Newimg/banner.png);
  background-color: #6c9be9;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 25px;
}

.banner-list-wenzi {
  width: 100%;
  height: 465px;
  margin: 0 auto;
  display: flex;
}

.banner-left {
  flex: 1;
  height: 463px;
  background-color: #ffffff;
}

.banner-title {
  width: 276px;
  height: 44px;
  background: linear-gradient(90deg, #f7f7f7 0%, #ffffff 100%);
  border-radius: 4px 4px 0px 0px;
}

.banner-title p {
  width: 200px;
  height: 45px;
  line-height: 45px;
  margin-left: 30px;
  color: #999999;
  font-size: 18px;
  font-weight: 600;
}

.imgs {
  width: 150px;
  height: 200px;
  text-align: center;
  margin-bottom: 10px;
}
.banner-title-list {
  padding: 5px 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 276px;
  float: left;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #141414;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
}

.banner-title-list:hover {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
}

.left-li ul {
  list-style: none;
}

.left-li li {
  height: 25px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #999999;
  float: left;
  margin-right: 10px;
  line-height: 25px;
}

.left-li li:hover {
  color: red;
}

.left-list ul {
  list-style: none;
  margin-top: 20px;
  cursor: pointer;
}

.left-list li {
  width: 130px;
  height: 30px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #999999;
  float: left;
  line-height: 30px;
}

.left-list li:hover {
  color: red;
}

.banner-lunbo {
  flex: 3;
  height: 358px;
  background-color: #187cb7;
  margin: 0 20px 20px 20px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.right {
  flex: 1;
  height: 358px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;
}

.right-bottom {
  margin-left: 20px;
  box-sizing: border-box;
  height: 85px;
  display: flex;
}

.right-bottom li {
  width: 25%;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  margin: 0 15px 0 0;
  justify-content: space-between;
  border-radius: 5px;
  background-color: white !important;
}

.right-bottom li img {
  width: 95px;
  height: 30px;
  transform: scale(0.6);
}
.right-bottom li:last-child {
  margin-right: 0;
}

.right-bottom li img {
  height: 75px;
  object-fit: contain;
}

.right-bottom p {
  width: auto;
  height: 17px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
}

.right-list {
  width: 216px;
  height: 298px;
  margin: 10px;
}

.right-list-wen {
  width: 216px;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.wenzi-title {
  width: 100px;
  height: 20px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #6c9be9;
  line-height: 20px;
  margin-left: 10px;
}

.gengduo {
  cursor: pointer;
  width: 47px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.wenzi-footer {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-left: 10px;
}

.router:hover {
  color: red;
  cursor: pointer;
}
.router p {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-content: center;
  height: 34px;
  line-height: 17px;
}
.wenzi-footer .wenzi-article {
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  align-items: center;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  padding-right: 10px;
}
.wenzi-footer .wenzi-article span{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  border-radius: 2px;
  justify-content: center;
  margin: 0;
  background: #bababa;
  margin-right: 10px;
  
}
.wenzi-footer .wenzi-article div{
   overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}
.wenzi-footer .wenzi-article:hover {
  color: red;
}

.active-list p {
  width: 100px;
  height: 40px;
  margin-left: 10px;
  line-height: 60px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
}

.active-list span {
  width: 108px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
  margin-left: 10px;
}

.book {
  width: 100%;
  height: auto;
  background-color: #00ff00;
  margin-top: 20px;
}

.book-list {
  width: 1290px;
  height: auto;

  margin: auto;
}

.list {
  display: flex;
  flex-flow: row wrap;
}

.item {
  height: 320px;
  flex: 0 0 20%;
}

.item img {
  display: block;
  width: 220px;
  height: 284px;
}

.ssdew {
  background: #ffffff;
}

.item span {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #141414;
  line-height: 22px;
  display: block;
  text-align: center;
  margin: 10px 80px;
}

.mthems {
  margin: auto;
  width: 68px;
  height: 3px;
  background: #6c9be9 !important;
  border-radius: 0px;
  /* opacity: 0.42; */
}

.footBanner {
  width: 80%;
  margin: 0 auto;
  background-color: white;
}

.footBannersss {
  width: 312px;
  height: 280px;
}

.footBanners {
  height: 230px;
  background: #ffffff;
}

.footBanners-s {
  padding: 20px;
}

.zzname {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.wenzi {
  width: 368px;
  height: 72px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.my-bullet {
  width: 0.1rem;
  height: 0.1rem;
  display: inline-block;
  border-radius: 50%;
  background: #b7b6b9;
}

.my-bullet:nth-child(2) {
  margin: 0 0.1rem;
}

.my-bullet-active {
  background: #fd882e;
}

.sanity-check {
  width: 276px;
  height: 80px;
  background: #ffffff;
  cursor: pointer;
}

.sanity-check:hover {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
}

.book-name:hover {
  color: red;
}

.swiper-button-prev,
.swiper-button-next {
  color: #000000;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 14px;
}

.carousel-img {
  width: 100%;
  height: 100%;
}
.ces {
  /* padding:20px 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #6c9be9;
}
.go_more {
  width: 20px;
  height: 15px;
}
.aes {
  height: 37px !important;
}
.des {
  float: left;
  width: 100%;
  height: 25px;
  overflow: hidden;
}
.el-popover__reference {
  display: flex !important;
  justify-content: cneter !important;
  align-items: center !important;
  flex: 1;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
}
.down {
  margin: 15px auto;
  width: 80%;
  border-radius: 10px;
  overflow: hidden;
}
.bg {
  background: white !important;
}
body {
  background-color: white;
}
.flexcolumn {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.flex1 {
  flex: 1;
}
.down .adjpg {
  width: 100%;
  height: 100%;
}
.wenzi-footer .wenzi-article .color1 {
  background: #fa9999;
}
.wenzi-footer .wenzi-article .color2 {
  background: #f7b697;
}
.wenzi-footer .wenzi-article .color3 {
  background: #a5d8a1;
}
</style>
